import PhoneInput from './phone_input';
import axios from 'axios';
import '@/misc/axios-config';

export default class ContactTherapistForm {
  constructor() {
    new PhoneInput();

    this.initializeCallProviderLinkTracker();
  }

  initializeCallProviderLinkTracker() {
    document.querySelectorAll('.contact-therapist-form .call-provider, [data-js-track-call]').forEach((callLink) => {
      callLink.addEventListener('click', async (e) => {
        const therapistId = e.currentTarget.dataset.therapist || e.target.dataset.therapist;
        const therapistSlug = e.currentTarget.dataset.slug || e.target.dataset.slug;

        await axios.post(`/therapists/${therapistId}/${therapistSlug}/track-call`);
      });
    });
  }
}
