import Rails from '@rails/ujs';
import * as ActiveStorage from 'activestorage';

import 'jquery';
import 'popper.js';
import 'bootstrap';
require('@nathanvda/cocoon');
import 'es6-promise/auto';
import ComponentsLoader from './components/loader';

Rails.start();
ActiveStorage.start();

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.tr-clickable').forEach((tr) =>
    tr.addEventListener('click', (ev) => {
      ev.target.querySelector('a')?.click();
    })
  );
});

document.addEventListener('DOMContentLoaded', () => {
  new ComponentsLoader();
  if (!document.location.hash) window.scrollTo(0, 0);
});
