import Alpine from 'alpinejs';

export default class LicensesHandler {
  constructor() {
    this.initializeNewLicenseAddition();
    this.initializeUnsavedLicensureRemover();
    this.removeDeleteLicenseButtonWhenThereIsOnlyOneLicense();
    this.handleSubmit();
    this.initializeApp({ initial: true });
  }

  initializeNewLicenseAddition() {
    $('.profile-form-wrapper').on('cocoon:after-insert', () => {
      this.initializeApp();
    });
  }

  initializeUnsavedLicensureRemover() {
    let formWrapper = document.querySelector('.profile-form-wrapper');

    formWrapper.addEventListener('click', function (evt) {
      let removeLicense = evt.target.closest('a.delete-license');

      if (removeLicense && removeLicense.classList.contains('remove-license')) {
        evt.preventDefault();

        let licenseFields = removeLicense.parentNode;
        let licenseFieldsParent = licenseFields.parentNode;

        if (licenseFieldsParent.querySelectorAll('.license-fields').length == 1) {
          return null;
        }

        licenseFieldsParent.removeChild(licenseFields);
      }
    });
  }

  removeDeleteLicenseButtonWhenThereIsOnlyOneLicense() {
    let form = document.querySelector('.profile-form-wrapper form');
    let licensesFields = form.querySelectorAll('.license-fields');

    if (licensesFields.length == 1) {
      let removeLicenseButton = licensesFields[0].querySelector('.delete-license');
      licensesFields[0].removeChild(removeLicenseButton);
    }
  }

  initializeApp({ initial } = {}) {
    document.addEventListener('alpine:init', () => {
      Alpine.data('wtclicenseinfo', () => ({
        display: true,
        therapistType: null,
        state: null,
        init() {
          this.display = this.$el.dataset.jsWtclicensecont == 'secondary' ? this.$data.associate : true;
          this.$el.querySelector('div').setAttribute('x-show', 'display');

          const selectType = this.$el.querySelector('[data-js-wtclicense-therapisttype]');
          const selectTypeOptions = selectType.querySelectorAll('[data-js-wtclicense-associate]');
          const selectState = this.$el.querySelector('[data-js-wtclicense-state]');
          const psypactCont = this.$el.querySelector('[data-js-wtclicense-psypact]');
          const licenseCodeCont = this.$el.querySelector('[data-js-wtclicense-licensecode]');
          const medicalInstitutionCont = this.$el.querySelector('[data-js-wtclicense-medicalinstitution]');
          const organizationAffiliationCont = this.$el.querySelector('[data-js-wtclicense-organizationaffiliation]');
          const licenseLegend = this.$el.querySelector('[data-js-wtclicense-legend]');

          selectType.setAttribute('x-model.fill', 'therapistType');
          selectType.setAttribute('x-ref', 'therapistTypeSelect');
          selectTypeOptions.forEach((opt) => {
            opt.setAttribute('data-js-wtclicense-default', opt.textContent);
            opt.setAttribute('x-text', 'selectTypeOption');
            opt.setAttribute('x-bind:value', 'selectTypeOption');
            opt.setAttribute('x-show', 'selectTypeShow');
          });
          selectState?.setAttribute('x-model.fill', 'state');
          psypactCont?.setAttribute('x-show', 'displayPsypact');
          licenseCodeCont?.setAttribute('x-show', 'displayLicenseCode');
          medicalInstitutionCont?.setAttribute('x-show', 'displayMedicalInstitution');
          organizationAffiliationCont?.setAttribute('x-show', 'displayOrganizationAffiliation');

          if (licenseLegend) {
            licenseLegend.setAttribute('text', licenseLegend.textContent);
          }

          this.$watch('$data.associate', (value) => {
            this.display = this.$el.dataset.jsWtclicensecont == 'secondary' ? value : true;
            this.$refs.therapistTypeSelect.value = '';
            if (licenseLegend) {
              licenseLegend.textContent = licenseLegend.getAttribute('text');
              if (value) {
                licenseLegend.textContent = licenseLegend.textContent.replace('license number', 'registration number');
              }
            }
          });

          setTimeout(() => {
            selectType?.dispatchEvent(new Event('change'));
            selectState?.dispatchEvent(new Event('change'));
          }, 10);
        },
        selectTypeShow() {
          return Boolean(this.selectTypeOption());
        },
        selectTypeOption() {
          const key = this.$data.associate && this.$root.dataset.jsWtclicensecont == 'main' ? 'associate' : 'default';
          return this.$el.getAttribute(`data-js-wtclicense-${key}`);
        },
        displayPsypact() {
          return this.therapistType === 'Psychologist';
        },
        displayLicenseCode() {
          return this.state === 'pr' && ['Psychologist', 'Psychiatrist'].includes(this.therapistType);
        },
        displayMedicalInstitution() {
          return ['Psychiatrist'].includes(this.therapistType);
        },
        displayOrganizationAffiliation() {
          return ['Clinical Psychologist'].includes(this.therapistType);
        }
      }));

      Alpine.data('wtclicensewidget', () => ({
        associate: null
      }));
    });

    document.querySelectorAll('[data-js-wtclicense]').forEach(async (el) => {
      // race condition fix
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
      el.removeAttribute('data-js-wtclicense');
      const associateCheckbox = el.querySelector('[data-js-wtclicense-associate-checkbox]');
      el.setAttribute('x-data', 'wtclicensewidget');
      associateCheckbox?.setAttribute('x-model.boolean.fill', 'associate');
      el.querySelectorAll('[data-js-wtclicensecont]').forEach((cont) => {
        cont.setAttribute('x-data', 'wtclicenseinfo');
      });
    });

    if (initial) {
      Alpine.start();
    }
  }
  handleSubmit() {
    document.querySelectorAll('[data-js-wtc-license-save-button]').forEach((btn) => {
      const submit = (ev) => {
        ev.preventDefault();
        [...document.querySelectorAll('[x-data=wtclicensewidget] input')].filter((input) => {
          if (!input.offsetParent && input.getAttribute('type') !== 'hidden') input.removeAttribute('name');
        });
        ev.target.removeEventListener('click', submit);
        ev.target.click();
      };

      btn.addEventListener('click', submit);
    });
  }
}
